import { useState, useEffect, ChangeEvent, useRef } from 'react';
// types
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
// libaries
import { toast } from 'react-toastify';
// components
import Modal from "@ui/Modal";
// assetts
import placeholderImage from '@assets/images/placeholder/placeholder.png';
// icons
import { BlackArrow } from '@icons/Common';
import Input from '@ui/Input';
import CheckBox from '@ui/CheckBox';
import { FaXmark } from 'react-icons/fa6';
import DropDown, { DropDownOption } from '@ui/DropDown';
import { Region, tournamentRegionToString } from '@src/firestore/tournaments';

const tournamentRegionOptions: DropDownOption[] = [
  {
    value: Region.EMEA,
    label: tournamentRegionToString(Region.EMEA)
  },
  {
    value: Region.NA,
    label: tournamentRegionToString(Region.NA)
  },
  {
    value: Region.LATAM,
    label: tournamentRegionToString(Region.LATAM)
  },
  {
    value: Region.APAC,
    label: tournamentRegionToString(Region.APAC)
  },
  {
    value: Region.GLOBAL,
    label: tournamentRegionToString(Region.GLOBAL)
  },
];

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  stepNames: string[]
}

const DetailsStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, setTournamentInfo, stepNames}) => {
  const cardImageInputRef = useRef<HTMLInputElement | null>(null);
  const bannerInputRef = useRef<HTMLInputElement | null>(null);

  const [stepValid, setStepValid] = useState<boolean>(true);

  const handleCardImageOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const cardImage = files[0];

      if (!["image/jpeg", "image/png", "image/webp"].includes(cardImage.type)) {
        toast.error(`You can only upload jpeg, png or webp image formats`)
      } else {
        const bannerUrl = URL.createObjectURL(cardImage);
        setTournamentInfo({
          ...tournamentInfo,
          cardImageFile: cardImage,
          cardImagePreviewUrl: bannerUrl
        });
      }
    } else {
      setTournamentInfo({
        ...tournamentInfo,
        cardImageFile: null,
        cardImagePreviewUrl: '',
      })
    }
  }

  const handleBannerOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const bannerImage = files[0];

      if (!["image/jpeg", "image/png", "image/webp"].includes(bannerImage.type)) {
        toast.error(`You can only upload jpeg, png or webp image formats`)
      } else {
        const bannerUrl = URL.createObjectURL(bannerImage);
        setTournamentInfo({
          ...tournamentInfo,
          bannerImageFile: bannerImage,
          bannerImagePreviewUrl: bannerUrl
        });
      }

    } else {
      setTournamentInfo({
        ...tournamentInfo,
        bannerImageFile: null,
        bannerImagePreviewUrl: '',
      })
    }
  }

  const removeCardImageAttachement = () => {
    setTournamentInfo({
      ...tournamentInfo,
      cardImageFile: null,
      cardImagePreviewUrl: '',
    })
  }

  const removeBannerAttachement = () => {
    setTournamentInfo({
      ...tournamentInfo,
      bannerImageFile: null,
      bannerImagePreviewUrl: '',
    })
  }

  useEffect(() => {
    if (tournamentInfo.name && tournamentInfo.cardImagePreviewUrl && tournamentInfo.bannerImagePreviewUrl) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [tournamentInfo])

  return (
    <Modal title="Details"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           step={2}
           totalSteps={10}
           scroll={true}
           open={currentStep === CreationFlowPage.details}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">next</span>
              <BlackArrow />
            </div>
           )}
           onBackClick={() => changeStep(CreationFlowPage.preset)}
           buttonOnClick={() => changeStep(CreationFlowPage.game)}>
      <div className="flex flex-col gap-y-3 w-full items-start text-start">
        <Input label='Name' placeholder='Name'
               value={tournamentInfo.name}
               onChange={(newValue) => setTournamentInfo({...tournamentInfo, name: newValue})}
               black={true}/>

        <div className="flex flex-col gap-y-2">
          <p>Region</p>
          <DropDown currentOption={tournamentRegionOptions.find((option) => option.value === tournamentInfo.region)!}
                    options={tournamentRegionOptions}
                    handleOnChange={(newValue) => {
                      setTournamentInfo({
                        ...tournamentInfo,
                        region: newValue
                      })
                    }}
                    black={true}/>
        </div>

        <div className='w-full flex flex-col gap-y-2'>
          <p className='text-sm text-steelGray font-compact'>Card Image</p>
          <div className='relative w-[60%] h-fit'>
            {tournamentInfo.cardImagePreviewUrl || tournamentInfo.cardImage ? (
              <button type='button' aria-label='remove banner attachement'
                      onClick={removeCardImageAttachement}
                      className='absolute flex items-center justify-center z-[2] top-2 right-2 w-[25px] h-auto aspect-square rounded-full bg-lightGray text-red/70
                                hover:opacity-75 transition-opacity '>
                <FaXmark/>
              </button>
            ) : ''}
            <button type='button' aria-label='change banner attachement'
                    disabled={tournamentInfo.cardImagePreviewUrl !== ''}
                    onClick={() => {
                      if (cardImageInputRef.current) {
                        cardImageInputRef.current.click();
                      }
                    }}
                    className='w-full h-auto aspect-[2] rounded-lg overflow-hidden relative
                            border border-lightGray'>
              <input type="file" accept=".jpg, .jpeg, .png, .webp"
                    ref={cardImageInputRef}
                    onChange={handleCardImageOnChange} className='hidden'/>
              <img src={tournamentInfo.cardImagePreviewUrl ? tournamentInfo.cardImagePreviewUrl : placeholderImage}
                className='w-full h-full object-cover'/>
            </button>
          </div>
        </div>

        <div className='w-full flex flex-col gap-y-2'>
          <p className='text-sm text-steelGray font-compact'>Banner Image</p>
          <div className='relative w-full h-full'>
            {tournamentInfo.bannerImagePreviewUrl || tournamentInfo.bannerImage ? (
              <button type='button' aria-label='remove banner attachement'
                      onClick={removeBannerAttachement}
                      className='absolute flex items-center justify-center z-[2] top-2 right-2 w-[25px] h-auto aspect-square rounded-full bg-lightGray text-red/70
                                hover:opacity-75 transition-opacity '>
                <FaXmark/>
              </button>
            ) : ''}
            <button type='button' aria-label='change banner attachement'
                    disabled={tournamentInfo.bannerImagePreviewUrl !== ''}
                    onClick={() => {
                      if (bannerInputRef.current) {
                        bannerInputRef.current.click();
                      }
                    }}
                    className='w-full h-[100px] rounded-lg overflow-hidden relative
                            border border-lightGray'>
              <input type="file" accept=".jpg, .jpeg, .png, .webp"
                    ref={bannerInputRef}
                    onChange={handleBannerOnChange} className='hidden'/>
              <img src={tournamentInfo.bannerImagePreviewUrl ? tournamentInfo.bannerImagePreviewUrl : placeholderImage}
                className='w-full h-full object-cover'/>
            </button>
          </div>
        </div>

        <button type='button' aria-label='Toggle tournament recurring'
                disabled={true}
                onClick={() => false}
                className='flex items-center gap-x-3 p-2 bg-lightGray hover:bg-ebonyClay disabled:hover:bg-lightGray disabled:opacity-50 transition-colors
                           font-compact text-steelGray rounded-lg uppercase text-sm'>

          <span className='translate-y-[1px]'>Recurring</span>
          <CheckBox selected={false} setSelected={() => false} asDiv={true} interactive={false}/>
        </button>
      </div>
    </Modal>
  )
}

export default DetailsStep;

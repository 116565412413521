import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// firebase
import { firestore, storage } from '@src/firebase';
import { collection, addDoc, updateDoc, Timestamp, onSnapshot} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
// types
import { Tournament, tournamentConverter } from '@src/firestore/tournaments';
// utils
import { formatLink } from '@utils/Link';
import { resizeImage } from '@utils/ResizeImage';
// components
import PresetStep from './steps/PresetStep';
import DetailsStep from './steps/DetailsStep';
import GameStep from './steps/GameStep';
import FormatStep from './steps/FormatStep';
import EntryStep from './steps/EntryStep';
import PrizeStep from './steps/PrizeStep';
import CommsStep from './steps/CommsStep';
import DateStep from './steps/DateStep';
import SavePresetStep from './steps/SavePresetStep';
import ReviewStep from './steps/ReviewStep';
// libaries
import { toast } from 'react-toastify';
// icons
import { FaPlus } from 'react-icons/fa';
import { Platform } from '@customTypes/Platforms';

export interface TournamentInfo extends Tournament {
  presetName: string,
  presetSaved: boolean,
  presetLoaded: string,
  cardImageFile: File | null,
  bannerImageFile: File | null,
  cardImagePreviewUrl: string,
  bannerImagePreviewUrl: string,
  dateChosen: boolean,
}

const defaultTournamentInfo: TournamentInfo = {
  // Extended Info
  presetName: '',
  presetSaved: false,
  presetLoaded: '',
  cardImageFile: null,
  bannerImageFile: null,
  cardImagePreviewUrl: '',
  bannerImagePreviewUrl: '',
  discordSupport: '',
  dateChosen: false,
  // Tournament Type
  id: '',
  brand: '',
  highPerformance: false,
  format: '',
  matchPointFormatThreshold: 40,
  matchPointFormatMaxGames: 6,
  matchPointFormatFinish: false,
  matchPointFormatPlannedMaps: [],
  announce: 0,
  matchDataPresent: false,
  winner: null,
  entrantsCount: 0,
  winningsDistributed: false,
  startedStages: [],
  hidden: true,
  name: 'New tournament',
  summary: '',
  active: true,
  status: 0,
  statusDates: {
    confirmation: new Date(new Date().getTime() + 5_000_000),
    ongoing: new Date(new Date().getTime() + 9_000_000),
    results: new Date(new Date().getTime() + 15_000_000),
  },
  region: 1,
  entryFee: 0,
  prizePool: {
    totalPrizePool: 150,
    currency: '$',
    prizes: [
      {
        special: false,
        position: 1,
        prizeName: '1st',
        prizeDescription: 'placement',
        prizeAmount: 75,
      },
      {
        special: false,
        position: 2,
        prizeName: '2nd',
        prizeDescription: 'placement',
        prizeAmount: 50,
      },
      {
        special: false,
        position: 3,
        prizeName: '3rd',
        prizeDescription: 'placement',
        prizeAmount: 25,
      },
    ]
  },
  game: 0,
  gameMode: 'Battle Royale: Trios',
  rules: '',
  teamSize: 3,
  maxTeamSize: 5,
  premium: false,
  teamCapacity: 20,
  winners: [],
  cardImage: '',
  bannerImage: '',
  platform: Platform.crossplay,
  completed: false,
  lockedStages: [],
  stagesInPlay: [],
  pointSystem: '',
  totalStages: 0,
  activeStage: 0,
  interStages: -1,
  streams: {
    activeStream: '',
    twitch: '',
    youtube: '',
  },
  vods: {
    activeVod: '',
    twitch: '',
    youtube: '',
  },
  createdAt: new Date(),
}

export enum CreationFlowPage {
  preset,
  details,
  game,
  format,
  entry,
  prize,
  comms,
  date,
  savePreset,
  review
}

export type CreationFlowStep = CreationFlowPage | -1;

const CreateTournament = () => {
  const navigate = useNavigate();

  const [tournamentInfo, setTournamentInfo] = useState<TournamentInfo>(defaultTournamentInfo);

  const stepNames = ['Load Preset', 'Details', 'Game', 'Format', 'Entry', 'Prize', 'Comms', 'Date', 'Save Preset', 'Review'];

  const [step, setStep] = useState<CreationFlowStep>(-1);
  const [presets, setPresets] = useState<TournamentInfo[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<TournamentInfo | null>(null);
  const [selectedPresetInitiallySet, setSelectedPresetInitiallySet] = useState<boolean>(false);

  useEffect(() => {
    if (presets.length > 0 && !selectedPresetInitiallySet) {
      setSelectedPreset(presets[0]);
      setSelectedPresetInitiallySet(true);
    }
  }, [presets, selectedPresetInitiallySet]);

  const getPresets = () => {
    const tournamentPresetCollection = collection(firestore, 'tournamentPresets');

    const unsubscribe = onSnapshot(tournamentPresetCollection, (snapshots) => {
      const rawPresets = snapshots.docs.map((doc) => doc.data()) as TournamentInfo[];
      const presetsDateFormatted = rawPresets.map((preset) => ({
        ...preset,
        cardImagePreviewUrl: preset.cardImage,
        bannerImagePreviewUrl: preset.bannerImage,
        statusDates: {
          confirmation: new Date((preset.statusDates.confirmation as unknown as Timestamp).seconds * 1000),
          ongoing: new Date((preset.statusDates.ongoing as unknown as Timestamp).seconds * 1000),
          results: new Date((preset.statusDates.results as unknown as Timestamp).seconds * 1000),
        }
      }))
      setPresets(presetsDateFormatted);
    })

    return unsubscribe;
  }

  useEffect(() => {
    const unsubscribe = getPresets();

    return () => unsubscribe();
  })

  const loadSelectedPreset = () => {
    if (selectedPreset) {
      setTournamentInfo({
        ...selectedPreset,
        presetLoaded: selectedPreset.presetName,
        presetSaved: false,
        presetName: '',
        dateChosen: false
      });
      toast.info('Preset Loaded')
    }
  }

  const savePreset = async () => {
    const cardImage = tournamentInfo.cardImageFile;
    const bannerImage = tournamentInfo.bannerImageFile;

    let newCardImageUrl = '';
    let newBannerImageUrl = '';

    if (cardImage) {
      const cardImageResized = await resizeImage(cardImage, {width: 1000});
      if (cardImageResized) {
        const cardImageStorageRef = ref(storage, `tournamentPresets/${tournamentInfo.presetName}/cardImage.webp`);
        await uploadBytes(cardImageStorageRef, cardImageResized).then(async (snapshot) => {
          newCardImageUrl = await getDownloadURL(snapshot.ref);
        });
      } else {
        console.error('error uploading card image');
        return false;
      }
    }

    if (bannerImage) {
      const bannerImageRezied = await resizeImage(bannerImage, {width: 1500});
      if (bannerImageRezied) {
        const bannerImageStorageRef = ref(storage, `tournamentPresets/${tournamentInfo.presetName}/bannerImage.webp`);
        await uploadBytes(bannerImageStorageRef, bannerImageRezied).then( async (snapshot) => {
          newBannerImageUrl = await getDownloadURL(snapshot.ref);
        });
      } else {
        console.error('error uploading banner');
        return false;
      }
    }

    const localTournamentInfo = {...tournamentInfo};
    localTournamentInfo.cardImage = newCardImageUrl !== '' ? newCardImageUrl : localTournamentInfo.cardImage;
    localTournamentInfo.bannerImage = newBannerImageUrl !== '' ? newBannerImageUrl : localTournamentInfo.bannerImage;
    localTournamentInfo.cardImageFile = null;
    localTournamentInfo.bannerImageFile = null;

    const tournamentPresetCollection = collection(firestore, 'tournamentPresets');
    const creationPromise = addDoc(tournamentPresetCollection, localTournamentInfo);

    const tournamentPresetDocRef = await creationPromise;
    const updatePromise = updateDoc(tournamentPresetDocRef, {
      id: tournamentPresetDocRef.id
    })

    const combinedPromise = Promise.all([creationPromise, updatePromise]);
    toast.promise(combinedPromise, {
      pending: 'Saving preset',
      success: 'Preset saved',
      error: 'Error saving preset'
    })

    setTournamentInfo({...tournamentInfo, presetSaved: true});
    await combinedPromise;
    return true;
  }

  const createTournament = async () => {
    // image upload
    const cardImage = tournamentInfo.cardImageFile;
    const bannerImage = tournamentInfo.bannerImageFile;

    let cardImageUrl = '';
    let bannerImageUrl = '';


    if (cardImage) {
      const cardImageResized = await resizeImage(cardImage, {width: 1000});
      if (cardImageResized) {
        const cardImageStorageRef = ref(storage, `tournaments/${tournamentInfo.name}/cardImage.webp`);
        await uploadBytes(cardImageStorageRef, cardImageResized).then(async (snapshot) => {
          cardImageUrl = await getDownloadURL(snapshot.ref);
        });
      } else {
        console.error('error uploading card image');
        return false;
      }
    }

    if (bannerImage) {
      const bannerImageRezied = await resizeImage(bannerImage, {width: 1500});
      if (bannerImageRezied) {
        const bannerImageStorageRef = ref(storage, `tournaments/${tournamentInfo.name}/bannerImage.webp`);
        await uploadBytes(bannerImageStorageRef, bannerImageRezied).then( async (snapshot) => {
          bannerImageUrl = await getDownloadURL(snapshot.ref);
        });
      } else {
        console.error('error uploading banner');
        return false;
      }
    }

    const convertedTournament = tournamentConverter.toFirestore(tournamentInfo as Tournament);
    convertedTournament.cardImage = cardImageUrl !== '' ? cardImageUrl : tournamentInfo.cardImage;
    convertedTournament.bannerImage = bannerImageUrl !== '' ? bannerImageUrl : tournamentInfo.bannerImage;
    convertedTournament.discordSupport = formatLink(tournamentInfo.discordSupport);
    convertedTournament.prizePool.totalPrizePool = convertedTournament.prizePool.prizes.reduce((acc, curr) => acc + curr.prizeAmount, 0);

    if (convertedTournament.format === 'Match Point') {
      const matchPointFormatPlannedMaps = [];
      for (let i = 2; i <= convertedTournament.matchPointFormatMaxGames; i++) {
        matchPointFormatPlannedMaps.push({
          gameNum: i,
          map: 'WE'
        })
      }
      convertedTournament.matchPointFormatPlannedMaps = matchPointFormatPlannedMaps;
    }

    const tournamentCollection = collection(firestore, 'tournaments').withConverter(tournamentConverter);
    const creationPromise = addDoc(tournamentCollection, convertedTournament as unknown as Tournament);

    const tournamentDocRef = await creationPromise;

    const updatePromise = await updateDoc(tournamentDocRef, {
      id: tournamentDocRef.id
    })

    const combinedPromise = Promise.all([creationPromise, updatePromise]);

    toast.promise(combinedPromise, {
      pending: 'Creating tournament',
      success: 'Tournament created',
      error: 'Error creating tournament'
    })

    await combinedPromise;

    navigate(`/tournaments/${tournamentDocRef.id}`);

    return true;
  }

  return (
    <>
      <button type='button' aria-label='Create Tournament'
              onClick={() => setStep(CreationFlowPage.preset)}
              className='flex flex-col items-center justify-center gap-x-2 w-full md:w-[200px] p-2 font-wide uppercase font-semibold rounded-lg
                       bg-lightBlack border border-lightGray h-[150px]
                         hover:opacity-75 text-steelGray hover:text-white hover:fill-white transition-all'>
        <span className='pt-[2px]'>
          Add
        </span>
        <FaPlus className='w-[30px] h-auto aspect-square'/>
      </button>

      <PresetStep currentStep={step}
                  stepNames={stepNames}
                  changeStep={setStep}
                  tournamentInfo={tournamentInfo}
                  setTournamentInfo={setTournamentInfo}
                  presets={presets}
                  selectedPreset={selectedPreset}
                  setSelectedPreset={setSelectedPreset}
                  loadSelectedPreset={loadSelectedPreset}/>

      <DetailsStep currentStep={step}
                   stepNames={stepNames}
                   changeStep={setStep}
                   tournamentInfo={tournamentInfo}
                   setTournamentInfo={setTournamentInfo}/>

      <GameStep currentStep={step}
                stepNames={stepNames}
                changeStep={setStep}
                tournamentInfo={tournamentInfo}
                setTournamentInfo={setTournamentInfo}/>

      <FormatStep currentStep={step}
                  stepNames={stepNames}
                  changeStep={setStep}
                  tournamentInfo={tournamentInfo}
                  setTournamentInfo={setTournamentInfo}/>

      <GameStep currentStep={step}
                stepNames={stepNames}
                changeStep={setStep}
                tournamentInfo={tournamentInfo}
                setTournamentInfo={setTournamentInfo}/>

      <EntryStep currentStep={step}
                stepNames={stepNames}
                changeStep={setStep}
                tournamentInfo={tournamentInfo}
                setTournamentInfo={setTournamentInfo}/>

      <PrizeStep currentStep={step}
                 stepNames={stepNames}
                changeStep={setStep}
                tournamentInfo={tournamentInfo}
                setTournamentInfo={setTournamentInfo}/>

      <CommsStep currentStep={step}
                 stepNames={stepNames}
                  changeStep={setStep}
                  tournamentInfo={tournamentInfo}
                  setTournamentInfo={setTournamentInfo}/>

      <DateStep currentStep={step}
                stepNames={stepNames}
                changeStep={setStep}
                tournamentInfo={tournamentInfo}
                setTournamentInfo={setTournamentInfo}/>

      <SavePresetStep currentStep={step}
                  stepNames={stepNames}
                  changeStep={setStep}
                  tournamentInfo={tournamentInfo}
                  setTournamentInfo={setTournamentInfo}
                  savePreset={savePreset}/>

      <ReviewStep currentStep={step}
                  stepNames={stepNames}
                  changeStep={setStep}
                  tournamentInfo={tournamentInfo}
                  setTournamentInfo={setTournamentInfo}
                  createTournament={createTournament}/>
    </>
  )
}

export default CreateTournament;
